@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.kontakt-page {
  font-family: 'Arial', sans-serif;
  color: #010510;
  background-color: #ffffff;
}

.kontakt-hero {
  position: relative;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

.kontakt-hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('C:/Users/malik/pb/src/images/hero/hero1.jpg'); /* Erstat med et passende googleads-relateret baggrundsbillede */
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  will-change: transform;
}

.kontakt-hero h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #010510; /* Ændret til hvid for bedre kontrast mod baggrunden */
  margin: 0;
  position: relative;
  z-index: 2;
  text-align: left;
}

.kontakt-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ændret fra stretch til flex-start */
  padding: 4rem 6rem;
  max-width: 1920px;
  margin: 0 auto;
}

.kontakt-info {
  margin-bottom: 4rem;
  text-align: left;
}

.kontakt-info h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  letter-spacing: -1px;
}

.kontakt-info p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  max-width: 600px;
}

.kontakt-detaljer {
  margin-top: 3rem;
  border-top: 1px solid #e0e0e0;
  padding-top: 2rem;
}

.kontakt-detaljer p {
  font-size: 1.1rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.kontakt-detaljer p strong {
  min-width: 80px; /* Juster dette efter behov */
  font-weight: 600;
}

.kontakt-info a {
  color: #010510;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease;
}

.kontakt-info a:hover {
  border-bottom: 1px solid #010510;
}

.kontakt-form {
  display: grid;
  gap: 2rem;
  width: 100%;
  max-width: 700px;
}

.form-group {
  position: relative;
  margin-bottom: 2rem;
}

.form-group label {
  position: absolute;
  top: 0.75rem;
  left: 0.5rem;
  font-size: 1rem;
  color: #757575;
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cccccc;
  background-color: transparent;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-bottom: 2px solid #010510;
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
  top: -1.2rem;
  left: 0;
  font-size: 0.8rem;
  color: #010510;
}

.form-group textarea {
  width: 100%;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #cccccc;
  background-color: transparent;
  transition: all 0.3s ease;
  resize: none;
  min-height: 40px; /* Samme højde som input-felterne */
  max-height: 200px;
  overflow-y: hidden; /* Skjul scrollbar som standard */
}

.form-group textarea:focus,
.form-group textarea:not(:placeholder-shown) {
  min-height: 100px; /* Øg minimumshøjden når der er fokus eller indhold */
  overflow-y: auto; /* Vis scrollbar når nødvendigt */
}

/* Behold de eksisterende regler for scrollbar styling */

.form-group textarea::-webkit-scrollbar {
  width: 8px;
}

.form-group textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.form-group textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.form-group textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.kontakt-form .cta-button {
  margin-top: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .kontakt-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .kontakt-info {
    flex: 1;
    margin-right: 6rem;
    margin-bottom: 0;
  }

  .kontakt-form {
    flex: 1;
  }
}

/* Tilføj denne nye regel for at sikre, at knappen forbliver centreret */
.kontakt-form .cta-button {
  text-align: center;
}

.kontakt-icon {
  margin-right: 1rem;
  width: 20px;
  color: #010510; /* Sørg for, at ikonerne er sorte */
}

.error-message {
  color: #7c7c7c;
  font-size: 0.8rem;
  margin-top: 0.2rem;
  position: absolute;
  bottom: -1.2rem;
  left: 0;
  display: block; /* Sikrer, at fejlbeskeden altid vises */
}

.form-group {
  position: relative;
  margin-bottom: 2rem;
}

.kontakt-form .cta-button {
  text-align: center;
}

.kontakt-icon {
  margin-right: 1rem;
  width: 20px;
  color: #010510; /* Sørg for, at ikonerne er sorte */
}
