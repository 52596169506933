.meeting-page {
  background-color: #ffffff;
}

.meeting-hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 4rem 2rem;
}

.meeting-hero-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.meeting-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #010510;
  text-shadow: none;
}

.meeting-hero p {
  font-size: 1.3rem;
  color: #333333;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-shadow: none;
}

.meeting-info-section {
  padding: 4rem 5rem;
  background-color: #ffffff;
}

.meeting-info-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  align-items: center;
}

.info-item {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  padding: 2rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 130px;
  justify-content: center;
}

.info-item:hover {
  transform: translateY(-2px);
  border-color: #929292;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
}

.info-item i {
  font-size: 3rem;
  color: #010510;
  margin-bottom: 1.5rem;
}

.info-item span {
  font-size: 1.2rem;
  color: #333333;
  line-height: 1.6;
}

.meeting-calendar-section {
  padding: 2rem;
  background-color: #ffffff;
  scroll-margin-top: 80px;
  position: relative;
}

.meeting-calendar {
  max-width: 1000px;
  margin: 0 auto;
  min-height: 700px;
  position: relative;
}

.process-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #010510;
}

.process-arrow i {
  font-size: 2rem;
}

.calendar-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #010510;
  margin-bottom: 2rem;
  padding-top: 4rem;
}

@media (max-width: 768px) {
  .meeting-hero {
    min-height: auto;
    padding: 6rem 1rem;
  }

  .meeting-hero-content {
    padding: 1rem;
  }

  .meeting-calendar-section {
    padding: 1rem;
  }

  .meeting-hero h1 {
    font-size: 2.5rem;
  }

  .meeting-hero p {
    font-size: 1.1rem;
  }

  .meeting-info-section,
  .meeting-calendar-section {
    padding: 3rem 1rem;
  }

  .meeting-info-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .info-item {
    height: auto;
    min-height: 150px;
    padding: 1.5rem;
  }

  .info-item span {
    font-size: 1rem;
  }

  .calendar-title {
    font-size: 2rem;
    padding-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .kundeafsnit {
    padding: 4rem 1rem;
  }

  .process-arrow {
    transform: rotate(90deg);
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  .meeting-hero h1 {
    font-size: 2rem;
  }

  .meeting-hero {
    padding: 4rem 1rem;
  }
}

@media (max-width: 1024px) {
  .meeting-info-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .process-arrow {
    transform: rotate(90deg);
    margin: 1rem 0;
  }
}

.meeting-page .kundeafsnit {
  background-color: #ffffff;
  color: #010510;
  padding: 6rem 2rem;
  text-align: center;
}

.meeting-page .testimonial-content {
  background-color: transparent;
  color: #010510;
}

.meeting-page .testimonial-content::before,
.meeting-page .testimonial-content::after {
  background-color: rgba(1, 5, 16, 0.2);
}

.meeting-page .testimonial-content:hover {
  background-color: rgba(1, 5, 16, 0.05);
}

.meeting-page .testimonial-name,
.meeting-page .testimonial-company,
.meeting-page .testimonial-text,
.meeting-page .testimonial-rating {
  color: #010510;
}

.meeting-page .carousel .control-arrow {
  background: rgba(1, 5, 16, 0.3);
}

.meeting-page .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #010510;
}

.meeting-page .carousel .control-next.control-arrow:before {
  border-left: 8px solid #010510;
}

@media (max-width: 768px) {
  .meeting-page .kundeafsnit {
    padding: 4rem 1rem;
  }
}

.mobile-cta {
  display: none;
  width: 100%;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {
  .mobile-cta {
    display: block;
  }
}

@media (max-width: 1080px) {
  .calendly-inline-widget {
    height: 1320px !important;
  }
  
  .meeting-calendar {
    min-height: 1320px;
  }
}

@media (max-width: 698px) {
  .calendly-inline-widget {
    height: 1200px !important;
  }
  
  .meeting-calendar {
    max-height: 1200px;
  }
}