@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.kundeudtalelser {
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: var(--body-font);
}

.custom-carousel .carousel .slide {
  background: transparent;
}

.testimonial-item {
  max-width: 90%;
  margin: 0 auto;
  cursor: pointer;
  height: 100%; /* Tilføjet for at sikre fuld højde */
}

.testimonial-content {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
  position: relative;
}

.testimonial-content::before,
.testimonial-content::after {
  content: '';
  position: absolute;
  left: 5%;
  right: 5%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.testimonial-content::before {
  top: 0;
}

.testimonial-content::after {
  bottom: 0;
}

.testimonial-content:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Tilføj denne nye regel for at gøre sideboksene mindre */
.carousel .slide:not(.selected) .testimonial-content {
  transform: scale(0.9);
  opacity: 0.85;
}

.testimonial-text {
  font-size: 1rem;
  font-style: bold;
  margin-bottom: 1rem;
  line-height: 1.4;
  flex-grow: 1; /* Tillader teksten at vokse og fylde tilgængeligt rum */
  overflow: hidden; /* Skjuler overskydende tekst */
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Begrænser teksten til 3 linjer */
  -webkit-box-orient: vertical;
}

.testimonial-name {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
  opacity: 0.8;
}

.testimonial-company {
  font-size: 0.8rem;
  opacity: 0.7;
  margin-bottom: 0.25rem;
}

.testimonial-rating {
  font-size: 2rem;
  color: #ffffff;
  margin-top: 1rem;
}

.carousel .control-dots {
  bottom: -2.5rem;
}

.carousel .control-dots .dot {
  background: #ffffff;
  box-shadow: none;
  opacity: 0.5;
}

.carousel .control-dots .dot.selected {
  opacity: 1;
}

.carousel .control-arrow {
  display: none;
  opacity: 0.8;
  transition: all 0.2s ease;
}

.carousel .control-arrow:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.2);
}

.carousel.carousel-slider .control-arrow {
  background: rgba(255, 255, 255, 0.3);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .control-prev.control-arrow {
  left: -1.5rem;
}

.carousel .control-next.control-arrow {
  right: -1.5rem;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel .slide {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.carousel .slide.selected {
  opacity: 1;
}

@media (max-width: 768px) {
  .carousel.carousel-slider .control-arrow {
    display: none;
  }
  
  .custom-carousel .carousel .slide {
    padding: 0.5rem;
  }
  
  .testimonial-content {
    padding: 1.5rem;
  }
  
  .testimonial-text {
    font-size: 0.9rem;
  }

  .carousel.carousel-slider {
    padding: 0;
  }

  .carousel .slide {
    padding: 0;
  }

  .testimonial-item {
    max-width: 100%;
    height: auto; /* Tillader variabel højde */
  }

  .testimonial-content {
    transform: none !important;
    opacity: 1 !important;
    height: 300px; /* Fast højde på 300px */
    display: flex;
    flex-direction: column;
  }

  .testimonial-text {
    -webkit-line-clamp: unset; /* Fjerner linjebegrænsningen */
    flex-grow: 1; /* Lader teksten vokse for at fylde tilgængeligt rum */
    overflow: visible; /* Viser al tekst */
  }

  /* Ny regel for at sikre lige højde */
  .carousel .slide {
    height: auto !important;
  }

  .carousel .slider-wrapper {
    height: auto !important;
  }

  .testimonial-content {
    height: max-content;
    min-height: 200px;
    max-height: 300px; /* Tilføj en maksimal højde */
    overflow: hidden; /* Skjul overskydende indhold */
  }

  .testimonial-text {
    -webkit-line-clamp: 5; /* Begræns til 4 linjer */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Tilføj ... når teksten er for lang */
  }

  .testimonial-rating {
    font-size: 1.6rem;
  }
}
