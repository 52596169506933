@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.footer {
  background-color: #ffffff;
  padding: 5rem 5%;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr; /* Ændret fra 1.5fr til 2fr for første kolonne */
  gap: 3rem;
  margin: 0 auto;
  align-items: start;
}

.logo-section {
  display: flex;
  flex-direction: column;
}

.logo-container {
  height: 3rem; /* Øget fra 2.5rem til 3rem */
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem; /* Tilføj denne linje for at matche margin under overskrifterne */
  margin-top: 5px; /* Tilføj denne linje for at flytte logoet lidt ned */
}

.footer-logo {
  max-height: 100%;
  width: auto;
  object-fit: contain;
  object-position: left top;
}

.footer-divider {
  width: 100%;
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 1rem 0;
}

.footer-description {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6; /* Øg line-height for bedre læsbarhed */
  text-align: left;
  margin-top: 1rem; /* Tilføj lidt margin i toppen */
  transition: none; /* Fjern transition effekten */
}

.footer-section {
  flex: 1;
  margin-right: 4rem;
}

.footer-section:last-child {
  margin-right: 0;
}

.footer-section h3 {
  font-size: 1.4rem;
  color: #010510;
  margin-bottom: 1.5rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.5rem;
  text-align: left;
  margin-top: 0; /* Tilføj denne linje for at fjerne top margin */
}

.footer-section h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: #010510;
}

.footer-section p,
.footer-section a {
  font-size: 1rem;
  color: #010510;
  margin-bottom: 0.8rem;
  text-decoration: none;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
}

.footer-section p i,
.footer-section a i {
  margin-right: 10px;
  color: #010510;
}

.footer-section a {
  position: relative;
  overflow: hidden;
}

.footer-section a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #010510;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.footer-section a:hover::after {
  transform: translateX(0);
}

.footer-section p:not(.footer-description):hover,
.footer-section a:hover {
  transform: translateX(5px);
}

.footer-section nav {
  display: flex;
  flex-direction: column;
}

.social-icons {
  display: flex;
  gap: 1.5rem;
}

.social-icons a {
  font-size: 1.4rem;
  color: #010510;
  transition: transform 0.3s ease;
}

.social-icons a:hover {
  transform: translateY(-5px);
}

.footer-bottom {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom p {
  font-size: 0.9rem;
  color: #010510;
}

@media (max-width: 1400px) {
  .footer-content,
  .footer-bottom {
    max-width: 90%; /* Juster dette efter behov */
  }
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
  }

  .footer-section {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .logo-container {
    margin-top: 0; /* Fjern top margin på mobile enheder */
  }

  .social-icons {
    margin-top: 1rem;
  }
}

@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .logo-section {
    grid-column: span 2;
  }

  .footer-description {
    max-width: 80%;
  }

  .footer-section h3 {
    font-size: 1.2rem;
  }

  .footer-section p,
  .footer-section a {
    font-size: 0.9rem;
  }

  .social-icons {
    gap: 1rem;
  }

  .social-icons a {
    font-size: 1.2rem;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-bottom p {
    margin-bottom: 1rem;
  }
}

/* Fjern de eksisterende animationer og effekter, da vi har tilføjet nye */
