@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.faq-section {
  background-color: #ffffff;
  padding: 3rem 3.5rem 5rem 3.5rem; /* Fjernet horisontal padding */
  max-width: 1920px; /* Reduceret maksimal bredde */
  margin: 0 auto;
}

.faq-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  padding: 0 2rem; /* Tilføjet horisontal padding til containeren */
}

.faq-left {
  flex: 0 0 35%; /* Øget bredden lidt */
}

.faq-right {
  flex: 0 0 60%; /* Reduceret bredden lidt */
}

.faq-section h2 {
  font-size: 3rem;
  text-align: left;
  margin-bottom: 1rem; /* Reduceret margin-bottom */
  color: #010510;
  font-weight: 700;
  letter-spacing: -1px;
}

.faq-subtitle {
  font-size: 1.1rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: left;
}

.faq-cta {
  margin-top: 2rem;
  text-align: left;
}

.faq-cta .cta-button {
  margin-left: 0; /* Sikrer, at knappen er venstre-alignet */
}

/* Ny stil for mobil CTA-knap */
.faq-mobile-cta {
  display: none;
  margin-top: 2rem;
  text-align: center;
}

.faq-accordion {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.faq-item {
  border-bottom: 1px solid #e0e0e0;
}

.faq-question {
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 2rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #010510;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.faq-question:hover {
  color: #333333;
}

.faq-icon {
  width: 10px;
  height: 10px;
  border-right: 2px solid #010510;
  border-bottom: 2px solid #010510;
  transform: rotate(-45deg);
  transition: all 0.3s ease;
  margin-left: 1rem;
}

.faq-question:hover .faq-icon {
  transform: rotate(45deg);
}

.faq-item.active .faq-icon {
  transform: rotate(45deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}

.faq-item.active .faq-answer {
  max-height: 1000px;
  padding-bottom: 2rem;
  opacity: 1;
}

.faq-answer p {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555555;
}

@media (max-width: 768px) {
  .faq-section {
    padding: 3rem 0.5rem; /* Reduceret padding på siderne */
  }

  .faq-container {
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 0.5rem; /* Reduceret padding på siderne */
  }

  .faq-left, .faq-right {
    flex: 0 0 100%;
  }

  .faq-section h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  .faq-subtitle {
    text-align: center;
    margin-bottom: 0.5rem; /* Yderligere reduceret margin-bottom */
  }

  .faq-cta {
    text-align: center;
  }

  .faq-cta .cta-button {
    margin-left: auto;
    margin-right: auto;
  }

  .faq-question {
    font-size: 1.1rem;
    padding: 1.5rem 0;
  }

  .faq-answer p {
    font-size: 1rem;
  }

  .faq-mobile-cta {
    margin-top: 1.5rem; /* Reduceret margin-top */
  }
}

@media (max-width: 1024px) {
  .faq-container {
    flex-direction: column;
    gap: 0rem;
    padding: 0 0.5rem; /* Reduceret padding på siderne */
  }

  .faq-left, .faq-right {
    flex: 0 0 100%;
  }

  .faq-section h2 {
    font-size: 2.75rem;
    text-align: center;
  }

  .faq-subtitle {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0rem; /* Reduceret fra 2rem til 1rem */
  }

  .faq-cta {
    display: none; /* Skjul desktop CTA på tablet og mobil */
  }

  .faq-mobile-cta {
    display: block; /* Vis mobil CTA på tablet og mobil */
    margin-top: 2rem; /* Reduceret fra 3rem til 2rem */
  }

  .faq-question {
    font-size: 1.15rem;
    padding: 1.75rem 0;
  }

  .faq-answer p {
    font-size: 1.05rem;
  }
}
