@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.cases-b-slideshow {
  padding: 8rem 5rem;
  background-color: #010510; /* Ændret til sort baggrund */
  font-family: var(--body-font);
  color: #ffffff; /* Tilføjet hvid tekstfarve */
}

.cases-b-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 3rem; /* Øget afstand mellem elementer */
  max-width: 1920px; /* Øget maksimal bredde */
  margin: 0 auto;
  margin-bottom: 8rem; /* Øget afstand til knapperne */
}

.case-b-item {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  height: 100%; /* Ændret fra auto til 100% */
  text-decoration: none;
  color: inherit;
}

.case-b-item:nth-child(odd) {
  transform: translateY(-4rem); /* Øget forskydning */
}

.case-b-item:nth-child(even) {
  transform: translateY(4rem); /* Øget forskydning */
}

.case-b-item:hover {
  transform: scale(1.0) translateY(-4rem); /* For odd items */
}

.case-b-item:nth-child(even):hover {
  transform: scale(1.0) translateY(4rem); /* For even items */
}

.case-b-item:hover .case-b-button {
  background-color: #ffffff; /* Ændret til lysere grå ved hover */
  color: #010510; /* Beholdt sort tekstfarve */
}

.case-b-image-container {
  background-color: #010510;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-grow: 1; /* Ændret fra 0 til 1 */
  display: flex;
  flex-direction: column;
}

.case-b-image {
  width: 100%;
  padding-top: 75%; /* Bevar aspect ratio */
  position: relative;
  overflow: hidden;
  flex-grow: 1; /* Ændret fra 0 til 1 */
}

.case-b-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-b-info {
  margin-top: 1rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-b-details h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff; /* Ændret til hvid tekstfarve */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.case-b-details p {
  margin: 0.5rem 0 0;
  font-size: 0.9rem;
  color: #cccccc; /* Ændret til lysere grå for bedre læsbarhed på mørk baggrund */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.case-b-button {
  background-color: #494949; /* Ændret til hvid baggrund */
  color: #ffffff; /* Ændret til sort tekstfarve */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.cases-b-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;
  padding: 2rem 0;
}

.cases-b-buttons a {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .cases-b-slideshow {
    padding: 4rem 1rem;
  }

  .cases-b-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .case-b-item:nth-child(odd),
  .case-b-item:nth-child(even) {
    transform: translateY(0);
  }

  .case-b-item:hover,
  .case-b-item:nth-child(even):hover {
    transform: none;
  }

  .case-b-item:hover .case-b-button {
    background-color: #ffffff; /* Ændret til hvid baggrund for mobil */
    color: #010510; /* Beholdt sort tekstfarve */
  }

  .cases-b-buttons {
    flex-direction: row; /* Behold knapperne på en række */
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
    padding: 1rem 0;
  }
}

@media (max-width: 480px) { /* Nyt breakpoint for meget smalle skærme */
  .cases-b-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .cases-b-buttons a,
  .cases-b-buttons button {
    width: 100%;
    max-width: 300px;
  }
}
