@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.cases-page {
  padding: 8rem 5rem;
  background-color: #ffffff;
}

.cases-page h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 8rem; /* Øget fra 4rem til 8rem */
  color: #010510;
}

.cases-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 3rem;
  max-width: 1920px;
  margin: 0 auto;
}

.case-item {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  height: 100%;
  text-decoration: none; /* Tilføjet for at fjerne standard link-understregning */
  color: inherit; /* Bevar tekstfarven */
}

.case-item:nth-child(odd) {
  transform: translateY(-4rem);
}

.case-item:nth-child(even) {
  transform: translateY(4rem);
}

.case-image-container {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-grow: 1; /* Tillader container at vokse og fylde tilgængelig plads */
  display: flex;
  flex-direction: column;
}

.case-image {
  width: 100%;
  padding-top: 75%; /* Bevar aspect ratio */
  position: relative;
  overflow: hidden;
  flex-grow: 1; /* Tillader billedet at vokse og fylde tilgængelig plads */
}

.case-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-info {
  margin-top: 1rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-details h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #010510;
  text-align: left;
}

.case-details p {
  margin: 0.5rem 0 0;
  font-size: 0.9rem;
  color: #333333;
  text-align: left;
}

.case-button {
  background-color: #f0f0f0;
  color: #010510;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.case-item:hover .case-button {
  background-color: #010510;
  color: #ffffff;
}

.loading, .no-more {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.1rem;
  color: #666666;
}

.loading-spinner {
  display: block;
  width: 40px;
  height: 40px;
  margin: 20px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #010510;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Juster højden på loader-referencen */
#loaderRef {
  height: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .cases-page {
    padding: 4rem 5rem; /* Behold samme sidepadding som på større skærme */
  }

  .cases-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .case-item:nth-child(odd),
  .case-item:nth-child(even) {
    transform: translateY(0);
  }

  .case-item:hover,
  .case-item:nth-child(even):hover {
    transform: none;
  }

  /* Fjern denne del for at beholde hover-effekten på tablet og mobil */
  /*
  .case-item:hover .case-button {
    background-color: #f0f0f0;
    color: #010510;
  }
  */
}

@media (max-width: 768px) {
  .cases-page {
    padding: 4rem 1rem; /* Juster padding for mobil for at matche CasesSlideshow */
  }

  .cases-page h1 {
    font-size: 2.2rem; /* Reducer overskriftsstørrelsen på mobil */
    margin-bottom: 2.5rem;
  }

  .cases-grid {
    gap: 2rem; /* Reducer mellemrum mellem elementer på mobil */
  }
}

@media (max-width: 480px) {
  .cases-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .cases-buttons a,
  .cases-buttons button {
    width: 100%;
    max-width: 300px;
  }
}
