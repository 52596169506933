@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.googleads-page {
    font-family: 'Arial', sans-serif; /* Samme skrifttype som forsiden */
    color: #333; /* Samme tekstfarve som forsiden */
    background-color: #ffffff;
  }
  
  .googleads-header {
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
  .googleads-header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('C:/Users/malik/pb/src/images/hero/hero21.jpg'); /* Erstat med et passende googleads-relateret baggrundsbillede */
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
  
  .googleads-header h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #010510;
    margin: 0;
    padding: 1rem 2rem;
    position: relative;
    z-index: 2;
  }
  
  .googleads-hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 5rem 0rem 5rem;
    background-color: #ffffff;
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .googleads-hero-content {
    flex: 0 1 50%;
    padding-right: 2rem;
    padding-top: 0;
    margin-left: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .googleads-hero-content .cta-button {
    margin-left: 0; /* Fjerner eventuel venstre margin på knappen */
    align-self: flex-start; /* Sikrer, at knappen starter fra venstre */
  }
  
  .googleads-hero-title {
    font-size: 3.5rem;
    font-weight: 700;
    color: #010510;
    margin-bottom: 1.5rem;
    margin-left: 0;
    line-height: 1.2;
    text-align: left;
  }
  
  .googleads-hero-text {
    font-size: 1.3rem;
    color: #333333; /* Ændret til samme farve som forsiden */
    margin-bottom: 2rem;
    margin-left: 0;
    line-height: 1.6;
    text-align: left;
  }
  
  .googleads-hero-icon {
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .googleads-hero-icon svg {
    width: 300px;
    height: 300px;
    fill: #010510;
  }
  
  .googleads-fordele {
    padding: 4rem 5rem 0rem 5rem;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    max-width: 1920px;
  }
  
  .googleads-fordele-content {
    max-width: 800px;
    margin: 0 auto 5rem;
  }
  
  .googleads-fordele h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    color: #010510;
    font-weight: 700;
    letter-spacing: -1px;
  }
  
  .googleads-fordele > p {
    font-size: 1.2rem;
    color: #333333; /* Ændret til samme farve som forsiden */
    line-height: 1.6;
    margin-bottom: 3rem;
  }
  
  .googleads-fordele-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 4rem;
    margin-bottom: 5rem;
  }
  
  .googleads-fordel-item {
    background-color: #ffffff;
    padding: 2.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .googleads-fordel-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.05);
    border-color: #929292;
  }
  
  .googleads-fordel-icon {
    font-size: 3rem;
    color: #010510;
    margin-bottom: 1.5rem;
  }
  
  .googleads-fordel-item h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #010510;
    font-weight: 600;
  }
  
  .googleads-fordel-item p {
    font-size: 1rem;
    color: #010510;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .googleads-fordele {
      padding: 10rem 2rem;
    }
  }

  @media (max-width: 1200px) {
    .googleads-hero {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem 1rem;
      text-align: center;
    }
  
    .googleads-hero-content, .googleads-hero-icon {
      padding: 0 1rem;
      max-width: 100%;
      margin: 0 auto;
    }
  
    .googleads-hero-title {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 1rem;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  
    .googleads-hero-text {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 1.5rem;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .googleads-hero-content .cta-button {
      display: block;
      margin: 0 auto;
    }
  
    .googleads-hero-icon svg {
      width: 200px;
      height: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .googleads-hero {
      flex-direction: column;
      padding: 3rem 1rem;
      text-align: center;
    }

    .googleads-hero-content {
      flex: 1 1 100%;
      padding-right: 0;
      margin-bottom: 2rem;
    }

    .googleads-hero-title {
      font-size: 2rem;
      text-align: center;
    }

    .googleads-hero-text {
      font-size: 1rem;
      text-align: center;
    }

    .googleads-hero-content .cta-button {
      align-self: center;
      margin: 0 auto;
    }

    .googleads-search-preview {
      flex: 1 1 100%;
      height: 300px;
      margin: 2rem auto 0;
      max-width: 90%;
    }
  }

.googleads-search-preview {
  flex: 0 1 50%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  max-width: 500px;
  margin-left: 2rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  height: 370px; /* Tilføj en fast højde */
  display: flex;
  flex-direction: column;
}

.googleads-search-bar {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #010510;
}

.googleads-search-bar input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  font-size: 16px;
  background-color: transparent;
  color: #010510;
}

.googleads-search-button {
  padding: 10px 20px;
  background-color: #010510;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.googleads-search-button:hover {
  background-color: #333333;
}

.googleads-search-results {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
}

.googleads-search-result {
  margin-bottom: 30px; /* Øget fra 20px til 30px */
}

.googleads-search-result h3 {
  color: #010510;
  font-size: 18px;
  margin-bottom: 3px;
}

.googleads-result-url {
  color: #010510;
  font-size: 14px;
  margin-bottom: 3px;
}

.googleads-result-description {
  color: #010510;
  font-size: 14px;
  line-height: 1.3;
}

.googleads-top-result {
  border-left: 3px solid #010510;
  padding-left: 10px;
}

.googleads-placeholder-result {
  margin-top: 35px; /* Øget fra 25px til 35px */
}

.googleads-placeholder-result .placeholder-line {
  height: 10px;
  background-color: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 2px;
}

.googleads-placeholder-result .placeholder-line:first-child {
  width: 60%;
}

.googleads-placeholder-result .placeholder-line:last-child {
  width: 80%;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .googleads-hero {
    flex-direction: column;
  }

  .googleads-search-preview {
    margin-left: 0;
    margin-top: 2rem;
    max-width: 100%;
    height: 350px;
  }
}

.googleads-search-bar input.non-interactive {
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.googleads-search-button.non-interactive {
  cursor: default;
  pointer-events: none;
  opacity: 0.7; /* Gør knappen lidt mere gennemsigtig for at indikere, at den ikke er interaktiv */
}

.googleads-fordele .cta-button {
  margin-top: 3rem;
}