@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Helvetica', Arial, sans-serif;
  --body-font: 'Helvetica', Arial, sans-serif;
  --accent-font: 'Helvetica', Arial, sans-serif;
}

body {
  font-family: 'Helvetica', Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica', Arial, sans-serif;
}

.accent-text {
  font-family: 'Helvetica', Arial, sans-serif;
}

.forside {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #333;
  background-color: #ffffff;
}

.hero {
  position: relative;
  height: 55vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%) scaleX(-1);
  object-fit: cover;
  opacity: 0.4;
  filter: grayscale(100%);
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 2rem;
  padding-left: 5rem;
  color: #010510;
  margin-left: 0;
  text-align: left;
}

.hero h1 {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  color: #010510;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  text-shadow: none;
}

.hero h2 {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #010510;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  text-shadow: none;
}

.hero p {
  font-size: 1.3rem;
  color: #010510;
  margin-bottom: 2rem;
  line-height: 1.6;
  text-shadow: none;
}

.cta-button {
  margin: 0;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .hero-content {
    padding: 1rem 2rem;
    text-align: left;
  }
}

/* Tilføj responsivt design */
@media (max-width: 768px) {
  .hero {
    height: auto;
    min-height: 60vh;
    padding: 4rem 1rem;
  }

  .hero-content {
    padding: 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }
}

section {
  padding: 4rem 2rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

.fordele {
  padding: 0rem 5rem 0rem 5rem;
  background-color: #ffffff;
  text-align: center;
  max-width: 1920px;
  margin: 0 auto;
}

.fordele h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #010510;
  font-weight: 700;
  letter-spacing: -1px;
}

.fordele > p {
  max-width: 600px;
  margin: 0 auto 5rem;
  font-size: 1.2rem;
  color: #333333;
  line-height: 1.6;
}

.fordele-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 4rem;
  margin-bottom: 5rem;
}

.fordel-item {
  background-color: #ffffff;
  padding: 0rem;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.fordel-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  border-color: #929292;
}

.fordel-item i {
  font-size: 3rem;
  color: #010510;
  margin-bottom: 1.5rem;
}

.fordel-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #010510;
  font-weight: 600;
}

.fordel-item p {
  font-size: 1rem;
  color: #333333;
  line-height: 1.6;
}

.special-fordel-item {
  background-color: #ffffff;
  padding: 0rem;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.special-fordel-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  border-color: #929292;
}

.special-fordel-item i {
  font-size: 3rem;
  color: #1400c5;
  margin-bottom: 1.5rem;
}

.special-fordel-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #010510;
  font-weight: 600;
}

.special-fordel-item p {
  font-size: 1rem;
  color: #333333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .fordele {
    padding: 3rem 1rem;
  }

  .fordele h2 {
    font-size: 2.5rem;
  }

  .fordele > p {
    font-size: 1.1rem;
  }

  .fordele-grid {
    gap: 2rem;
  }
}

.proces-afsnit {
  background-color: #010510;
  color: #ffffff;
  padding: 8rem 4rem 0 0rem;
}

.proces-container {
  display: flex;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 5rem;
}

.proces-title {
  flex: 0 0 33.333%;
  padding-right: 4rem;
}

.proces-title h2 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1rem;
}

.proces-title p {
  font-size: 1.1rem;
  color: #cccccc;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: left;
}

.proces-afsnit-cta {
  text-align: left;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.proces-afsnit-cta .invert-cta-button {
  margin-left: 0;
}

.proces-trin {
  flex: 0 0 66.666%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.trin {
  background-color: #010510;
  padding: 2rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
}

.trin:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 255, 255, 0.3);
}

.trin h3 {
  color: #ffffff;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.trin p {
  color: #cccccc;
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 1200px) {
  .proces-afsnit {
    padding: 6rem 2rem;
  }
  
  .proces-container {
    flex-direction: column;
    padding: 0;
  }
  
  .proces-title, .proces-trin {
    flex: 0 0 100%;
  }
  
  .proces-title {
    margin-bottom: 3rem;
    padding-right: 0;
    text-align: center;
  }
  
  .proces-title h2 {
    text-align: center;
    font-size: 2.5rem;
  }
  
  .proces-title p {
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .proces-afsnit-cta {
    justify-content: center;
  }
  
  .proces-trin {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .proces-afsnit {
    padding: 4rem 1rem;
  }

  .proces-title h2 {
    font-size: 2.2rem;
  }

  .proces-trin {
    grid-template-columns: 1fr;
  }
  
  .trin {
    padding: 1.5rem;
  }
  
  .trin h3 {
    font-size: 1.2rem;
  }
  
  .trin p {
    font-size: 0.9rem;
  }
}

.kundeanmeldelser, .kundelogoer {
  background-color: #f9f9f9;
}

.ekstra-services {
  padding: 6rem 3rem 2rem;
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

.ekstra-services-content {
  max-width: 800px;
  margin: 0 auto 6rem;
  padding: 0 2rem;
}

.ekstra-services h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #010510;
  font-weight: 700;
}

.ekstra-services p {
  font-size: 1.2rem;
  color: #333333;
  line-height: 1.6;
}

.services-grid {
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding: 0 2rem;
}

.service-item {
  flex: 1;
  background-color: #ffffff;
  padding: 3rem 2rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #f0f0f0;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0,0,0,0.1);
}

.service-icon {
  font-size: 3rem;
  color: #010510;
  margin-bottom: 2rem;
}

.service-text h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #010510;
  font-weight: 600;
}

.service-text p {
  font-size: 1.1rem;
  color: #333333;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .services-grid {
    flex-wrap: wrap;
    gap: 2rem;
  }

  .service-item {
    flex-basis: calc(50% - 1rem);
    min-width: auto;
  }
}

@media (max-width: 768px) {
  .ekstra-services {
    padding: 4rem 1rem 2rem;
  }

  .ekstra-services-content {
    padding: 0 1rem;
    margin-bottom: 4rem;
  }

  .ekstra-services h2 {
    font-size: 2.2rem;
  }

  .ekstra-services p {
    font-size: 1rem;
  }

  .services-grid {
    flex-direction: column;
    gap: 2rem;
    padding: 0;
  }

  .service-item {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .service-icon {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .service-text h3 {
    font-size: 1.5rem;
  }

  .service-text p {
    font-size: 1rem;
  }

  .ekstra-services-cta {
    margin-top: 3rem;
  }
}

.faq {
  background-color: #f5f5f5;
  padding-top: 2rem;
}

.cases-section {
  text-align: center;
  padding-top: 4rem;
}

.cases-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.professionelt-webdesign {
  padding: 0 5rem 2rem 5rem;
  background-color: #ffffff;
  overflow: hidden;
}

.professionelt-webdesign-container {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.professionelt-webdesign-content {
  flex: 0 1 45%;
  padding-right: 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.professionelt-webdesign h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #010510;
  margin-top: 0rem;
}

.professionelt-webdesign p {
  font-size: 1.1rem;
  color: #333333;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.professionelt-webdesign-content .cta-button {
  align-self: flex-start;
  margin-left: 0;
}

.professionelt-webdesign-content .mobile-cta-button {
  display: none;
}

.professionelt-webdesign-visual {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  padding-right: 0;
}

.code-block {
  width: 100%;
  max-width: 450px;
  height: 330px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #010510;
  border-radius: 12px;
  padding: 2.5rem;
  box-shadow: 0 15px 30px rgba(0,0,0,0.15);
  transform: rotate(0deg);
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}

.code-block:hover {
  transform: scale(1.01);
  box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

.code-block pre {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

.code-block code {
  color: #8f8f8f !important;
  font-family: 'Fira Code', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  white-space: pre;
  text-align: left;
  display: block;
  width: 100%;
}

.code-content {
  display: block;
  text-align: left;
  width: 100%;
  overflow: hidden;
}

.code-block code .animated-text,
.code-block .animated-text {
  color: #ffffff !important;
  font-weight: semi-bold;
  display: inline;
  white-space: nowrap;
  overflow: visible;
  vertical-align: bottom;
}

.code-block code .cursor {
  color: #ffffff !important;
  font-weight: bold;
  animation: blink 0.7s infinite;
  display: inline-block;
  vertical-align: bottom;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.kundeafsnit {
  background-color: #010510;
  color: #ffffff;
  padding: 6rem 2rem;
  text-align: center;
}

.kundeafsnit h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.kundeudtalelser {
  max-width: 1900px;
  margin: 0 auto 4rem;
}

.kundelogoer {
  background-color: #010510;
  overflow: hidden;
  padding: 4rem 0;
}

.kundelogoer-container {
  display: flex;
  animation: scrollLogoer 30s linear infinite;
}

.kundelogoer img {
  height: 60px;
  margin: 0 2rem;
  filter: brightness(0) invert(1);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.kundelogoer img:hover {
  opacity: 1;
}

@keyframes scrollLogoer {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.ekstra-services-cta {
  margin-top: 4rem;
}

.kundeafsnit-cta {
  margin-top: 4rem;
}

.proces-afsnit-cta {
  text-align: center;
  margin-top: 4rem;
}

@media (max-width: 768px) {
  .professionelt-webdesign {
    padding: 4rem 1rem;
  }

  .professionelt-webdesign-container {
    flex-direction: column;
    padding: 0;
  }

  .professionelt-webdesign-content {
    flex: 1 1 100%;
    padding-right: 0;
    margin-bottom: 2rem;
    text-align: center;
    order: 1;
  }

  .professionelt-webdesign h2 {
    font-size: 2rem;
  }

  .professionelt-webdesign p {
    font-size: 1rem;
  }

  .professionelt-webdesign-visual {
    flex: 1 1 100%;
    margin-top: 2rem;
    padding-right: 0;
    order: 2;
  }

  .code-block {
    width: 300px;
    height: auto;
    padding: 1.5rem;
    margin: 0 auto 3rem;
  }

  .code-block code {
    font-size: 0.8rem;
  }

  .professionelt-webdesign-content .cta-button {
    display: none;
  }

  .code-block-mobile-cta-button {
    display: block;
    margin-top: 3rem;
  }
}

@media (max-width: 1300px) {
  .code-block {
    width: 350px;
    max-width: 100%;
  }

  .code-block pre,
  .code-block code,
  .code-content {
    overflow: visible;
  }
}

.code-block-mobile-cta-button {
  display: none;
}

@media (max-width: 768px) {
  .professionelt-webdesign-visual {
    flex: 1 1 100%;
    margin-top: 2rem;
    padding-right: 0;
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .code-block {
    width: 70vw;
    height: auto;
    padding: 1.5rem;
    margin: 0 auto 3rem;
  }

  .code-block code {
    font-size: 0.8rem;
  }

  .professionelt-webdesign-content .cta-button {
    display: none;
  }

  .code-block-mobile-cta-button {
    display: block;
    margin-top: 3rem;
  }
}

.proces-afsnit-cta.desktop-only {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
}

.proces-afsnit-cta.mobile-tablet-only {
  display: none;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-top: 4rem;
}

@media (max-width: 1200px) {
  .proces-afsnit-cta.desktop-only {
    display: none;
  }

  .proces-afsnit-cta.mobile-tablet-only {
    display: flex;
  }

  .kundeafsnit {
    padding: 0 2rem 2rem 2rem;
  }
}

@media (max-width: 768px) {
  .kundeafsnit {
    padding: 0 2rem 2rem 2rem;
  }

  .kundeafsnit h2 {
    font-size: 2.2rem;
    margin-bottom: 2.5rem;
  }

  .kundeudtalelser {
    margin-bottom: 3rem;
  }
}