@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.i-case-details-page {
  padding: 8rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  font-family: var(--body-font);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
}

.accent-text {
  font-family: var(--accent-font);
}

.i-back-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1rem;
  color: #000;
  cursor: pointer;
  margin-bottom: 2rem;
}

.i-back-button svg {
  margin-right: 0.5rem;
}

.i-case-image-container {
  position: relative;
  margin-bottom: 2rem;
}

.i-case-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.i-case-visit-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.i-case-image-container:hover .i-case-visit-button {
  opacity: 1;
}

.i-case-company {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.i-case-url {
  display: block;
  color: #666;
  text-decoration: none;
  margin-bottom: 2rem;
}

.i-case-testimonial {
  font-style: italic;
  font-size: 1.2rem;
  color: #666;
  /* Fjernet: border-left: 4px solid #000; */
  /* Fjernet: padding-left: 1rem; */
  margin-bottom: 2rem;
}

.i-case-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.i-case-phone-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.i-case-phone-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.i-case-design-details {
  background-color: #f8f8f8;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
}

.i-case-design-details h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
  text-align: center;
}

.i-design-details-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.i-case-colors, .i-case-fonts {
  flex: 1;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.i-case-colors h3, .i-case-fonts h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #444;
  text-align: center;
}

.i-color-samples {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.i-color-sample {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
}

.i-color-box {
  width: 60px;
  height: 60px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.i-color-code {
  font-size: 0.8rem;
  color: #666;
  text-align: center;
}

.i-case-fonts ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.i-case-fonts li {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  transition: background-color 0.3s ease;
}

.i-case-fonts li:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .i-design-details-container {
    flex-direction: column;
  }
  
  .i-case-design-details {
    padding: 2rem;
  }
}

.i-case-responsive-images {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.i-case-device-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.i-case-device-image-wrapper {
  width: 100%;
  padding-top: 100%; /* Dette skaber en 1:1 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.i-case-device-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .i-case-responsive-images {
    flex-direction: column;
  }
  
  .i-case-device-image-container {
    max-width: 400px;
    margin: 0 auto;
  }
}

.i-case-info-row {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 4rem;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 2rem;
}

.i-case-info-item {
  flex: 1;
  text-align: center;
}

.i-case-info-item h3 {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  margin-bottom: 0.5rem;
}

.i-case-info-item p, .i-case-info-item a {
  font-size: 1rem;
  color: #000;
  text-decoration: none;
}

.i-case-info-item a:hover {
  text-decoration: underline;
}

.i-case-testimonial-container {
  background-color: #ffffff;
  padding: 3rem;
  margin: 4rem 0;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); /* Ændret fra 0 10px 30px rgba(0, 0, 0, 0.1) */
  border: 1px solid #f0f0f0;
}

.i-case-testimonial {
  font-style: italic;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
  line-height: 1.6;
}

.i-case-testimonial::before,
.i-case-testimonial::after {
  content: '"';
  font-size: 4rem;
  color: #000;
  opacity: 0.1;
  position: absolute;
}

.i-case-testimonial::before {
  top: -2rem;
  left: -1rem;
}

.i-case-testimonial::after {
  bottom: -3rem;
  right: -1rem;
}

.i-case-testimonial-author {
  font-size: 0.9rem;
  color: #666;
  text-align: right;
  margin-top: 1rem;
  font-weight: 600;
}

.i-case-testimonial-author::before {
  content: '— ';
}

.i-case-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 4rem;
  color: #333;
}

@media (max-width: 768px) {
  .i-case-info-row {
    flex-direction: column;
    gap: 1rem;
  }

  .i-case-info-item {
    text-align: left;
  }

  .i-case-testimonial-container {
    padding: 2rem;
    margin: 3rem 0;
  }

  .i-case-testimonial {
    font-size: 1.1rem;
  }

  .i-case-testimonial::before,
  .i-case-testimonial::after {
    font-size: 3rem;
  }
}
