@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.pb-customer-logos {
  overflow: hidden;
  width: 100vw; /* Ændret fra 100% til 100vw */
  margin-left: calc(-50vw + 50%); /* Tilføjet for at centrere indholdet */
  padding: 8rem 0 0 0;
  font-family: var(--body-font);
}

.pb-customer-logos-container {
  display: flex;
  animation: pb-scroll-logos var(--animation-duration, 15s) linear infinite; /* Ændret fra 30s til 20s */
  width: max-content; /* Tilføj denne linje */
}

.pb-customer-logo {
  height: 60px; /* Øget fra 40px til 60px */
  width: auto; /* Tilføjet for at bevare aspektforholdet */
  margin: 0 5rem;
  filter: brightness(0) invert(0);
  opacity: 1;
  transition: opacity 0.3s ease;
  object-fit: contain; /* Tilføjet for at sikre, at hele logoet vises */
}

.pb-customer-logo:hover {
  opacity: 0.7;
}

@keyframes pb-scroll-logos {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

@media (prefers-reduced-motion: reduce) {
  .pb-customer-logos-container {
    animation: none;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .pb-customer-logo {
    margin: 2rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
}

.accent-text {
  font-family: var(--accent-font);
}
