@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.seo-page {
    font-family: 'Arial', sans-serif; /* Samme skrifttype som forsiden */
    color: #333; /* Samme tekstfarve som forsiden */
    background-color: #ffffff;
  }
  
  .seo-header {
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
  .seo-header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('C:/Users/malik/pb/src/images/hero/hero22.jpg'); /* Erstat med et passende SEO-relateret baggrundsbillede */
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
  
  .seo-header h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #010510;
    margin: 0;
    padding: 1rem 2rem;
    position: relative;
    z-index: 2;
  }
  
  .seo-hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8rem 4rem;
    background-color: #ffffff;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .seo-hero-content {
    flex: 0 1 50%;
    padding-right: 2rem;
    text-align: left; /* Tilføjet for at sikre venstrejustering */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Dette sikrer, at alt indhold starter fra venstre */
  }
  
  .seo-hero-content .cta-button {
    margin-left: 0; /* Fjerner eventuel venstre margin på knappen */
    align-self: flex-start; /* Sikrer, at knappen starter fra venstre */
  }
  
  .seo-hero-title {
    font-size: 3.5rem;
    font-weight: 700;
    color: #010510;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    text-align: left; /* Tilføjet for at sikre venstrejustering */
  }
  
  .seo-hero-text {
    font-size: 1.3rem;
    color: #333333; /* Ændret til samme farve som forsiden */
    margin-bottom: 2rem;
    line-height: 1.6;
    text-align: left; /* Tilføjet for at sikre venstrejustering */
  }
  
  .seo-hero-icon {
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .seo-hero-icon svg {
    width: 300px;
    height: 300px;
    fill: #010510;
  }
  
  .seo-fordele {
    padding: 10rem 5rem;
    background-color: #ffffff;
    text-align: center;
  }
  
  .seo-fordele-content {
    max-width: 800px;
    margin: 0 auto 5rem;
  }
  
  .seo-fordele h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    color: #010510;
    font-weight: 700;
  }
  
  .seo-fordele > p {
    font-size: 1.2rem;
    color: #333333; /* Ændret til samme farve som forsiden */
    line-height: 1.6;
  }
  
  .seo-fordele-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 4rem;
    margin-bottom: 5rem;
  }
  
  .seo-fordel-item {
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .seo-fordel-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
  
  .seo-fordel-icon {
    font-size: 3rem;
    color: #010510;
    margin-bottom: 1.5rem;
  }
  
  .seo-fordel-item h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #010510;
    font-weight: 600;
  }
  
  .seo-fordel-item p {
    font-size: 1rem;
    color: #010510;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .seo-fordele {
      padding: 10rem 2rem;
    }
  }

  .seo-proces {
    padding: 10rem 4rem;
    background-color: #010510;
    color: #ffffff;
    text-align: center;
  }
  
  .seo-proces h2 {
    font-size: 3rem;
    margin-bottom: 4rem;
    font-weight: 700;
  }
  
  .seo-proces-trin {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    margin-bottom: 5rem;
  }
  
  .seo-trin {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 12px;
    transition: all 0.3s ease;
  }
  
  .seo-trin:hover {
    transform: translateY(-5px);
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .seo-trin h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .seo-trin p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .seo-services {
    padding: 10rem 4rem;
    background-color: #ffffff;
    text-align: center;
  }
  
  .seo-services h2 {
    font-size: 3rem;
    margin-bottom: 4rem;
    color: #010510;
    font-weight: 700;
  }
  
  .seo-services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    margin-bottom: 5rem;
  }
  
  .seo-service-item {
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  }
  
  .seo-service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
  
  .seo-service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #010510;
    font-weight: 600;
  }
  
  .seo-service-item p {
    font-size: 1rem;
    color: #010510;
    line-height: 1.6;
  }
  
  @media (max-width: 1200px) {
    .seo-hero {
      flex-direction: column;
      align-items: flex-start;
      padding: 4rem 2rem;
    }
  
    .seo-hero-content, .seo-hero-icon {
      flex: 0 1 100%;
      max-width: 100%;
      margin-bottom: 2rem;
    }
  
    .seo-hero-title {
      font-size: 2.5rem;
    }
  
    .seo-hero-text {
      font-size: 1.1rem;
    }
  
    .seo-hero-icon svg {
      width: 200px;
      height: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .seo-hero {
      flex-direction: column;
      padding: 3rem 1rem;
      text-align: center;
    }

    .seo-hero-content {
      flex: 1 1 100%;
      padding-right: 0;
      margin-bottom: 2rem;
    }

    .seo-hero-title {
      font-size: 2rem;
      text-align: center;
    }

    .seo-hero-text {
      font-size: 1rem;
      text-align: center;
    }

    .seo-hero-content .cta-button {
      align-self: center;
      margin: 0 auto;
    }

    .seo-search-preview {
      flex: 0 0 auto;
      width: 100%;
      max-width: 350px;
      height: 350px; /* Behold den faste højde */
      margin: 2rem auto 0;
    }

    .search-bar {
      padding: 8px;
    }

    .search-bar input {
      font-size: 14px;
    }

    .search-button {
      padding: 8px 16px;
      font-size: 14px;
    }

    .search-results {
      flex-grow: 1;
      overflow-y: auto;
      padding: 12px;
    }

    .search-result h3 {
      font-size: 16px;
    }

    .result-url, .result-description {
      font-size: 12px;
    }
  }

.seo-search-preview {
  flex: 0 1 50%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  max-width: 500px;
  margin-left: 2rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  height: 350px; /* Fast højde */
  display: flex;
  flex-direction: column;
}

.search-bar {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #010510;
}

.search-bar input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  font-size: 16px;
  background-color: transparent;
  color: #010510;
}

.search-button {
  padding: 10px 20px;
  background-color: #010510;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #333333;
}

.search-results {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
}

.search-result {
  margin-bottom: 30px; /* Øget fra 20px til 30px */
}

.search-result h3 {
  color: #010510;
  font-size: 18px;
  margin-bottom: 3px;
}

.result-url {
  color: #010510;
  font-size: 14px;
  margin-bottom: 3px;
}

.result-description {
  color: #010510;
  font-size: 14px;
  line-height: 1.3;
}

.top-result {
  border-left: 3px solid #010510;
  padding-left: 10px;
}

.placeholder-result {
  margin-top: 35px; /* Øget fra 25px til 35px */
}

.placeholder-result .placeholder-line {
  height: 10px;
  background-color: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 2px;
}

.placeholder-result .placeholder-line:first-child {
  width: 60%;
}

.placeholder-result .placeholder-line:last-child {
  width: 80%;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .seo-hero {
    flex-direction: column;
  }

  .seo-search-preview {
    margin-left: 0;
    margin-top: 2rem;
    max-width: 100%;
  }
}

.search-bar input.non-interactive {
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.search-button.non-interactive {
  cursor: default;
  pointer-events: none;
  opacity: 0.7; /* Gør knappen lidt mere gennemsigtig for at indikere, at den ikke er interaktiv */
}

.seo-fordele .cta-button {
  margin-top: 3rem;
}

.googleads-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 4rem;
  background-color: #ffffff;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 4rem; /* Tilføj dette for at skabe afstand til næste sektion på desktop */
}

.googleads-hero-content {
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin-bottom: 2rem;
}

.googleads-hero h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #010510;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.googleads-hero-text {
  font-size: 1.1rem;
  color: #333333;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.googleads-search-preview {
  width: 100%;
  max-width: 500px;
  height: 350px; /* Fast højde */
  margin: 0 auto;
}

@media (min-width: 768px) {
  .googleads-hero {
    flex-direction: row;
    align-items: flex-start;
    padding: 8rem 4rem;
  }

  .googleads-hero-content {
    flex: 0 1 50%;
    text-align: left;
    padding-right: 2rem;
    margin-bottom: 0;
  }

  .googleads-hero h2 {
    font-size: 3rem;
  }

  .googleads-hero-text {
    font-size: 1.3rem;
  }

  .googleads-search-preview {
    flex: 0 1 50%;
    margin: 0;
  }

}

@media (max-width: 767px) {
  .googleads-hero-content .cta-button {
    margin: 0 auto;
  }

}

.mobile-tablet-only-seo {
  display: none;
  text-align: center;
  margin-top: 2rem;
  width: 100%; /* Tilføjet for at sikre fuld bredde */
}

@media (max-width: 1200px) {
  .mobile-tablet-only-seo {
    display: flex;
    justify-content: center; /* Centrerer indholdet horisontalt */
    align-items: center; /* Centrerer indholdet vertikalt */
  }

  .mobile-tablet-only-seo .cta-button {
    margin: 0;
    margin-top: 2rem;
  }
}

.desktop-only {
  display: block;
}

@media (max-width: 1200px) {
  .desktop-only {
    display: none;
  }
}

.customer-logos-section {
  padding-top: 4rem; /* Giver lidt plads over kundelogoerne på desktop */
}

@media (max-width: 1200px) {
  .googleads-hero {
    padding: 4rem 2rem;
    margin-bottom: 2rem; /* Reducer afstanden på tablet */
  }

  .customer-logos-section {
    padding-top: 0rem; /* Reducer den øverste padding på tablet */
  }
}

@media (max-width: 768px) {
  .googleads-hero {
    padding: 3rem 1rem;
    margin-bottom: 1rem; /* Yderligere reducer afstanden på mobil */
  }

  .customer-logos-section {
    padding-top: 0rem; /* Yderligere reducer den øverste padding på mobil */
  }
}