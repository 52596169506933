@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.admin-page {
  max-width: 1200px;
  margin: 80px auto 0; /* Tilføj top margin for at give plads til headeren */
  padding: 2rem;
  font-family: var(--body-font);
}

.admin-login {
  max-width: 400px;
  margin: 5rem auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-login h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.admin-login form {
  display: flex;
  flex-direction: column;
}

.admin-login input,
.admin-case-form input,
.admin-case-form textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-login button,
.admin-page button {
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-login button:hover,
.admin-page button:hover {
  background-color: #333;
}

.admin-page h1 {
  margin-bottom: 2rem;
}

.admin-case-form {
  display: flex;
  flex-direction: column;
}

.admin-case-form textarea {
  width: 100%;
}

.admin-colors-section,
.admin-fonts-section,
.admin-images-section {
  margin-bottom: 1rem;
}

.admin-colors-section input,
.admin-fonts-section input {
  width: calc(25% - 0.5rem);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.admin-image-input {
  margin-bottom: 1rem;
}

.admin-image-input label {
  display: block;
  margin-bottom: 0.5rem;
}

.admin-case-form button[type="submit"] {
  grid-column: span 2;
  justify-self: start;
}

.admin-cases-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.admin-case-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
}

.admin-case-item h3 {
  margin-top: 0;
}

.admin-case-item button {
  margin-right: 0.5rem;
}

.admin-logout-button {
  position: fixed; /* Ændret fra absolute til fixed */
  top: 90px; /* Justeret for at være under headeren */
  right: 20px;
  z-index: 1000; /* Sørg for, at den er over andre elementer */
}

.admin-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Øget z-index for at være over headeren */
}

.admin-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: calc(100vh - 160px); /* Justeret for at tage højde for headeren */
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 80px; /* Giver plads til headeren */
}

.admin-popup h2 {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
}

.admin-popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.admin-popup-buttons button {
  margin-left: 1rem;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.admin-popup-buttons button:first-child {
  background-color: #f0f0f0;
  color: #333;
}

.admin-popup-buttons button:last-child {
  background-color: #007bff;
  color: white;
}

.admin-popup-buttons button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.admin-add-case-button {
  margin-bottom: 1rem;
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.uploaded-image-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
}

.image-selection {
  margin-top: 20px;
}

.selected-image-preview {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
  display: block;
}

.admin-image-selection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2100; /* Højere z-index end admin-popup-overlay */
}

.admin-image-selection-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.admin-image-selection-popup h3 {
  margin-bottom: 1rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-bottom: 1rem;
}

.image-selection-thumbnail {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: transform 0.2s;
}

.image-selection-thumbnail:hover {
  transform: scale(1.05);
}

.admin-image-selection-popup button {
  display: block;
  margin: 0 auto;
}

.image-selection button {
  margin-bottom: 10px;
}

.admin-form-group {
  margin-bottom: 1.5rem;
}

.admin-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.admin-form-group input,
.admin-form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.admin-form-group input:focus,
.admin-form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.admin-form-group textarea {
  resize: vertical;
  min-height: 100px;
  max-height: 300px; /* Begrænser højden */
}

/* Fjern scrollbars fra tekstbokse */
.admin-form-group textarea::-webkit-scrollbar {
  display: none;
}

.admin-form-group textarea {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.admin-colors-section,
.admin-fonts-section {
  margin-top: 2rem;
}

.admin-colors-section h3,
.admin-fonts-section h3 {
  margin-bottom: 1rem;
}

.description-input-container {
  position: relative;
}

.ai-generate-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.ai-generate-button:hover {
  background-color: #45a049;
}

.ai-generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.admin-content {
  margin-top: 80px; /* Juster denne værdi baseret på din headers højde */
  padding: 20px;
}

.admin-images-section {
  margin-top: 2rem;
}

.admin-images-section h3 {
  margin-bottom: 1rem;
}

.image-selection {
  margin-bottom: 1.5rem;
}

.image-selection h4 {
  margin-bottom: 0.5rem;
}

.selected-image-preview {
  max-width: 200px;
  max-height: 200px;
  margin-top: 0.5rem;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.admin-form-group input[type="number"] {
  -moz-appearance: textfield;
}

.admin-form-group input[type="number"]::-webkit-outer-spin-button,
.admin-form-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.admin-outlet-section {
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.admin-template-card {
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
}

.template-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.admin-template-form .admin-form-group {
  margin-bottom: 1.5rem;
}

.admin-template-form input[type="checkbox"] {
  margin-right: 0.5rem;
}

.admin-add-button {
  margin-bottom: 1.5rem;
  background-color: #4CAF50;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-add-button:hover {
  background-color: #45a049;
}

.template-status {
  margin: 1rem 0;
  font-weight: 500;
}

.template-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0.5rem 0;
}

.delete-button {
  background-color: #dc3545 !important;
  color: white;
}

.delete-button:hover {
  background-color: #c82333 !important;
}

.outlet-images-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.outlet-image-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.outlet-image-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.remove-image-button {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.remove-image-button:hover {
  background: #c82333;
}

.admin-navigation {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.admin-nav-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.admin-nav-button::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.admin-nav-button.active {
  color: #007bff;
}

.admin-nav-button.active::after {
  background-color: #007bff;
}

.admin-nav-button:hover {
  color: #007bff;
  background: none;
}
