.datapolitik-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 120px;
  line-height: 1.6;
}

.datapolitik-container h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
}

.datapolitik-container h2 {
  font-size: 1.5rem;
  margin: 2rem 0 1rem;
  color: #444;
}

.datapolitik-container section {
  margin-bottom: 2rem;
}

.datapolitik-container ul {
  margin: 1rem 0;
  padding-left: 2rem;
}

.datapolitik-container li {
  margin-bottom: 0.5rem;
}

.datapolitik-container p {
  margin-bottom: 1rem;
}
