.telefon-knap {
  display: none;
}

@media (max-width: 1200px) {
  .telefon-knap {
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #010510;
    color: white;
    padding: 12px;
    border-radius: 50%;
    align-items: center;
    text-decoration: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    transition: all 0.3s ease;
  }

  .telefon-knap:hover {
    background-color: #1a1f2e;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  .telefon-ikon {
    font-size: 20px;
  }
  
  .telefon-nummer {
    display: none;
  }
}
