@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.om-page {
    font-family: 'Arial', sans-serif;
    color: #010510;
    background-color: #ffffff;
  }
  
  .om-header {
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
  .om-header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/hero/hero17.jpg');
    background-size: cover;
    background-position: center;
    transform: translateZ(0);
    will-change: transform;
  }
  
  .om-header h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #010510;
    margin: 0;
    padding: 1rem 2rem;
    position: relative;
    z-index: 2;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
  }
  
  .om-hero {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 5rem 0rem 5rem;
    background-color: #ffffff;
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .om-hero-content {
    flex: 0 1 50%;
    padding-right: 2rem;
    text-align: left;
  }
  
  .om-hero h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #010510;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .om-hero p {
    font-size: 1.3rem;
    color: #333333;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .om-team-members {
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 0;
    flex-wrap: nowrap; /* Ændret fra wrap til nowrap */
  }
  
  .om-team-member {
    flex: 0 1 calc(50% - 1rem);
    max-width: 300px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
  }
  
  .om-team-member:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
  
  .om-team-member img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
  
  .om-team-member-info {
    padding: 1.5rem;
  }
  
  .om-team-member h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #010510;
    margin-bottom: 0.5rem;
  }
  
  .om-team-member p {
    font-size: 0.9rem;
    color: #333333;
    line-height: 1.4;
    margin-bottom: 0.25rem;
  }
  
  .om-vaerdier {
    padding: 5rem 5rem 5rem 5rem;
    background-color: #ffffff;
    text-align: center;
  }
  
  .vaerdier-content {
    max-width: 800px;
    margin: 0 auto 5rem;
  }
  
  .om-vaerdier h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    color: #010510;
    font-weight: 700;
    letter-spacing: -1px;
  }
  
  .om-vaerdier > p {
    font-size: 1.2rem;
    color: #333333;
    line-height: 1.6;
    margin-bottom: 3rem;
  }
  
  .vaerdier-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 4rem;
    margin-bottom: 5rem;
  }
  
  .vaerdi-item {
    background-color: #ffffff;
    padding: 2.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .vaerdi-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.05);
    border-color: #929292;
  }
  
  .vaerdi-icon {
    font-size: 3rem;
    color: #010510;
    margin-bottom: 1.5rem;
  }
  
  .vaerdi-icon svg {
    width: 48px;
    height: 48px;
    fill: #010510;
  }
  
  .vaerdi-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #010510;
    font-weight: 600;
  }
  
  .vaerdi-item p {
    font-size: 1rem;
    color: #333333;
    line-height: 1.6;
  }
  
  .vaerdier-cta {
    margin-top: 4rem;
    text-align: center;
  }

  .vaerdier-cta .cta-button {
    font-size: 1.1rem;
    padding: 1rem 2rem;
  }

  @media (max-width: 768px) {
    .vaerdier-cta {
      margin-top: 3rem;
    }

    .vaerdier-cta .cta-button {
      font-size: 1rem;
      padding: 0.8rem 1.6rem;
    }
  }
  
  @media (max-width: 1024px) {
    .om-hero {
      flex-direction: column;
      padding: 3rem 2rem; /* Reduceret padding */
      align-items: center;
    }
  
    .om-hero-content {
      flex: 0 1 100%;
      max-width: 100%;
      margin-bottom: 1.5rem; /* Reduceret margin-bottom */
      text-align: center;
      padding-right: 0;
    }
  
    .om-team-members {
      flex: 0 1 100%;
      max-width: 100%;
      flex-direction: row;
      justify-content: center;
      gap: 1.5rem; /* Reduceret gap */
      margin-bottom: 1.5rem; /* Reduceret margin-bottom */
    }
  
    .om-team-member {
      flex: 0 1 calc(50% - 0.75rem);
      max-width: 280px; /* Lidt mindre for at passe bedre på mindre skærme */
    }
  
    .proces-afsnit-cta.mobile-tablet-only {
      display: block;
      text-align: center;
      margin-top: 1rem; /* Tilføjet mindre top margin */
    }
  
    .proces-afsnit-cta.desktop-only {
      display: none;
    }
  }
  
  @media (max-width: 1200px) and (min-width: 769px) {
    .om-hero {
      flex-direction: column;
      padding: 0 2rem;
      align-items: center;
    }

    .om-hero-content {
      flex: 0 1 100%;
      max-width: 100%;
      margin-bottom: 2rem;
      text-align: center;
      padding-right: 0;
    }

    .om-team-members {
      flex: 0 1 100%;
      max-width: 100%;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
      margin-bottom: 2rem;
    }

    .om-team-member {
      flex: 0 1 calc(50% - 1rem);
      max-width: 300px;
    }

    .proces-afsnit-cta.mobile-tablet-only {
      display: block;
      text-align: center;
    }

    .proces-afsnit-cta.desktop-only {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .om-hero {
      padding: 0rem 1.5rem; /* Yderligere reduceret padding for mobile */
    }
  
    .om-team-members {
      flex-direction: column;
      align-items: center;
    }
  
    .om-team-member {
      flex: 0 1 100%;
      max-width: 100%;
      width: 100%;
      margin-bottom: 1rem; /* Reduceret margin-bottom */
    }
  
    .proces-afsnit-cta.mobile-tablet-only {
      margin-top: 0.5rem; /* Yderligere reduceret top margin for mobile */
    }
  }
