@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.cta-button {
  background-color: #010510;
  color: #ffffff;
  border: 1px solid #010510; /* Tyndere kant */
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif; /* Moderne skrifttype */
  border-radius: 10px; /* Lidt mindre afrundede hjørner */
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 180px; /* Juster denne værdi efter behov */
}

.cta-button:hover {
  background-color: transparent; /* Ændret til gennemsigtig */
  color: #010510;
}

.cta-button span {
  transition: transform 0.3s ease;
}

.cta-button:hover span {
  transform: translateX(-10px);
}

.arrow-icon {
  position: absolute;
  right: 1.5rem;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 0.9rem; /* Mindre pilikon */
  color: #010510; /* Altid sort pil */
}

.cta-button:hover .arrow-icon {
  opacity: 1;
  transform: translateX(0);
}
