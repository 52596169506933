@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.invert-secondary-button {
  background-color: #010510;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 180px;
}

.invert-secondary-button:hover {
  background-color: #ffffff;
  color: #010510;
}

.invert-secondary-button span {
  transition: transform 0.3s ease;
}

.invert-secondary-button:hover span {
  transform: translateX(-10px);
}

.invert-secondary-button .arrow-icon-invert {
  position: absolute;
  right: 1.5rem;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  color: #010510;
}

.invert-secondary-button:hover .arrow-icon-invert {
  opacity: 1;
  transform: translateX(0);
}
