@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100vw;
    transition: all 0.3s ease;
}

.header:not(.header-scrolled) {
    background-color: transparent;
    box-shadow: none;
}

.header.header-scrolled {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 5%;
    max-width: 100%;
    margin: 0 auto;
    transition: padding 0.3s ease;
    position: relative;
}

.header.header-scrolled .header-content {
    padding: 1rem 5%;
}

.header-logo a {
    font-size: 1.5rem;
    font-weight: bold;
    color: #010510;
    text-decoration: none;
}

.header-nav-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.header-nav-menu ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 3rem;
}

.header-nav-menu li {
    margin: 0;
}

.header-nav-menu a {
    color: #010510;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    padding-bottom: 5px;
}

.header-nav-menu a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #010510;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.header-nav-menu a:hover::after,
.header-nav-menu a.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.header-cta-container {
    flex: 0 0 auto;
    margin-left: 2rem;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.header-phone {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #010510;
    text-decoration: none;
    font-weight: 500;
}

.header-phone:hover {
    color: #1a1f2e;
}

.header-phone-icon {
    font-size: 18px;
}

.header-menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.header-menu-toggle span {
    width: 2rem;
    height: 0.25rem;
    background-color: #010510;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
}

@media (max-width: 1200px) {
    .header-phone {
        display: none;
    }
}

@media (max-width: 768px) {
    .header-nav-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: #ffffff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: 999;
        overflow-y: auto;
        margin: 0;
        pointer-events: none;
        transform: none;
    }

    .header-nav-menu.header-open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .header-nav-menu ul {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .header-nav-menu li {
        margin: 0;
    }

    .header-menu-toggle {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 1000;
    }

    .header-menu-toggle span {
        width: 2rem;
        height: 0.25rem;
        background-color: #010510;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
    }

    .header-menu-toggle.header-open span:nth-child(1) {
        transform: rotate(45deg);
    }

    .header-menu-toggle.header-open span:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
    }

    .header-menu-toggle.header-open span:nth-child(3) {
        transform: rotate(-45deg);
    }

    .header-cta-container {
        display: none;
    }

    .header-phone {
        display: none;
    }

    .header-dropdown > span {
        display: none;
    }

    .header-dropdown-menu {
        position: static;
        box-shadow: none;
        opacity: 1;
        visibility: visible;
        transform: none;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        padding: 0;
    }

    .header-dropdown-menu::before {
        display: none;
    }

    .header-dropdown-menu a {
        padding: 0.5rem 0;
    }
}

.header-logo-image {
    height: 60px;
    width: auto;
    transition: all 0.3s ease;
}

.header.header-scrolled .header-logo-image {
    height: 40px;
}

.header-dropdown {
    position: relative;
}

.header-dropdown > span {
    cursor: pointer;
    font-weight: 500;
    color: #010510;
    padding-bottom: 5px;
    position: relative;
}

.header-dropdown > span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #010510;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.header-dropdown:hover > span::after {
    transform: scaleX(1);
}

.header-dropdown-menu {
    position: absolute;
    top: calc(100% + 15px);
    right: 0;
    transform: translateY(10px);
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.5rem;
    list-style-type: none;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.header-dropdown-menu::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 20px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
}

.header-dropdown:hover .header-dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.header-dropdown-menu li {
    margin: 0;
}

.header-dropdown-menu a {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: #010510;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border-radius: 4px;
}

.header-dropdown-menu a:hover {
    background-color: #f0f0f0;
}

.header-dropdown-menu i {
    margin-right: 10px;
    font-size: 1.2em;
    width: 20px;
    text-align: center;
}

@media (max-width: 768px) {
    .header-dropdown-menu {
        position: static;
        box-shadow: none;
        opacity: 1;
        visibility: visible;
        transform: none;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        padding: 0;
    }

    .header-dropdown-menu::before {
        display: none;
    }

    .header-nav-menu.header-open .header-dropdown-menu {
        display: flex;
    }
}