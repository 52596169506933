@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;700&display=swap');

:root {
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --accent-font: 'Playfair Display', serif;
}

.ai-chatbot-page {
  font-family: var(--body-font);
  color: #010510;
  background-color: #ffffff;
}

.ai-chatbot-hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 5rem;
  background-color: #ffffff;
  max-width: 1920px;
  margin: 0 auto;
}

.ai-chatbot-hero-content {
  flex: 0 1 50%;
  padding-right: 2rem;
  text-align: left;
}

.ai-chatbot-hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #010510;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.ai-chatbot-hero p {
  font-size: 1.3rem;
  color: #333333;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.chat-window {
  flex: 0 1 40%;
  height: 400px;
  max-width: 500px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

@media (max-width: 1200px) {
  .ai-chatbot-hero {
    flex-direction: column;
    align-items: center;
    padding: 4rem 5rem 0rem 5rem;
    text-align: center;
  }

  .ai-chatbot-hero-content {
    flex: 0 1 100%;
    padding-right: 0;
    margin-bottom: 2rem;
    text-align: center;
  }

  .ai-chatbot-hero h1 {
    font-size: 2.8rem;
  }

  .ai-chatbot-hero p {
    font-size: 1.2rem;
  }

  .chat-window {
    flex: 0 0 auto;
    width: 80%;
    max-width: 500px;
    height: 400px;
    margin: 2rem 0 4rem 0 ;
  }
}

@media (max-width: 768px) {
  .ai-chatbot-hero {
    padding: 4rem 3rem 0rem 3rem;
  }

  .ai-chatbot-hero h1 {
    font-size: 2.2rem;
  }

  .ai-chatbot-hero p {
    font-size: 1rem;
  }

  .chat-window {
    width: 100%;
    height: 350px;
  }
}

.chat-messages {
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.message {
  display: flex;
  align-items: center;
  max-width: 95%;
  margin-bottom: 1.5rem;
  animation: slideUp 0.5s ease-out forwards;
}

.message-content {
  padding: 1rem 1.2rem;
  border-radius: 18px;
  font-size: 1rem;
  line-height: 1.5;
  min-width: 150px;
  min-height: 2rem;
}

.message.bruger {
  align-self: flex-end;
}

.message.bruger .message-content {
  background-color: #ffffff;
  color: #010510;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.message.ai {
  align-self: flex-start;
}

.message.ai .message-content {
  background-color: #010510;
  color: #ffffff;
  margin-right: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.message-icon {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.message.bruger .message-icon {
  order: 1;
}

.message-icon svg {
  width: 28px;
  height: 28px;
  fill: #010510;
}

.typing-indicator {
  display: flex;
  align-items: center;
  animation: fadeIn 0.5s ease-out forwards;
  margin-top: 1.5rem;
}

.typing-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 32px;
  background-color: #010510;
  border-radius: 16px;
  margin: 0 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.message.bruger .typing-dots {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
  animation: wave 1.3s linear infinite;
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.2s;
}

.dot:nth-child(3) {
  animation-delay: -0.1s;
}

.message.bruger .dot {
  background-color: #010510;
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-6px);
  }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.ai-chatbot-hero-image {
  position: relative;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background-image: url('../images/hero/hero5.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.ai-chatbot-hero-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.ai-chatbot-hero-image h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #010510;
  margin: 0;
  padding: 1rem 2rem;
  position: relative;
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .ai-chatbot-hero-image {
    background-attachment: scroll;
  }
}

.ai-chatbot-header {
  position: relative;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.ai-chatbot-header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/hero/hero19.jpg');
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  will-change: transform;
}

.ai-chatbot-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #010510;
  margin: 0;
  padding: 1rem 2rem;
  position: relative;
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .ai-chatbot-header-background {
    background-attachment: scroll;
  }
}

.ai-chatbot-fordele {
  padding: 4rem 5rem 0rem 5rem;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;
  max-width: 1920px;
}

.fordele-content {
  max-width: 800px;
  margin: 0 auto 5rem;
}

.ai-chatbot-fordele h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #010510;
  font-weight: 700;
  letter-spacing: -1px;
}

.ai-chatbot-fordele > p {
  font-size: 1.2rem;
  color: #333333;
  line-height: 1.6;
  margin-bottom: 3rem;
}

.fordele-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 4rem;
  margin-bottom: 5rem;
}

@media (max-width: 1490px) {
  .fordele-grid {
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  }
}

.fordel-item {
  background-color: #ffffff;
  padding: 2.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.fordel-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  border-color: #929292;
}

.fordel-icon {
  font-size: 3rem;
  color: #010510;
  margin-bottom: 1.5rem;
}

.fordel-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #010510;
  font-weight: 600;
}

.fordel-item p {
  font-size: 1rem;
  color: #333333;
  line-height: 1.6;
}

.special-fordel-item {
  background-color: #ffffff;
  padding: 2.5rem;
  border: 1px solid #cfcfcf;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
}

.special-fordel-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  border-color: #929292;
}

.special-fordel-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #010510;
  font-weight: 600;
}

.special-fordel-item p {
  font-size: 1rem;
  color: #333333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .ai-chatbot-fordele {
    padding: 6rem 1rem;
  }

  .ai-chatbot-fordele h2 {
    font-size: 2.5rem;
  }

  .fordele-grid {
    grid-template-columns: 1fr;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
}

.accent-text {
  font-family: var(--accent-font);
}