.inbox-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 100px;
}

.inbox-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}

.inbox-back-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.inbox-back-button:hover {
  background: #0056b3;
}

.inbox-layout {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 30px;
  height: calc(100vh - 200px);
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  overflow: hidden;
}

.inbox-contacts-sidebar {
  background: #f8f9fa;
  border-right: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
}

.inbox-contacts-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.inbox-contact-item {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  cursor: pointer;
}

.inbox-contact-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.inbox-contact-item.selected {
  background: #e7f1ff;
  border-left: 4px solid #007bff;
}

.inbox-contact-info h3 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 16px;
}

.inbox-phone-number {
  color: #666;
  margin: 5px 0;
  font-size: 14px;
}

.inbox-time-added {
  color: #888;
  font-size: 12px;
  margin: 5px 0 0 0;
}

.inbox-chat-window {
  display: flex;
  flex-direction: column;
  background: white;
  height: 100%;
}

.inbox-chat-header {
  padding: 25px;
  border-bottom: 1px solid #eee;
  background: #fff;
}

.inbox-chat-header h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.inbox-chat-header p {
  margin: 5px 0 0;
  color: #666;
  font-size: 14px;
}

.inbox-message-form {
  padding: 25px;
  margin-top: auto;
  border-top: 1px solid #eee;
  background: #fff;
}

.inbox-message-form textarea {
  width: 100%;
  min-height: 120px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: vertical;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  transition: border-color 0.2s;
}

.inbox-message-form textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.1);
}

.inbox-message-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.inbox-char-count {
  color: #666;
  font-size: 13px;
}

.inbox-send-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
}

.inbox-send-button:hover:not(:disabled) {
  background: #0056b3;
  transform: translateY(-1px);
}

.inbox-send-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.inbox-no-contact-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-size: 16px;
  background: #f8f9fa;
}

.inbox-contact-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s;
}

.inbox-contact-item:hover .inbox-contact-buttons {
  opacity: 1;
}

.inbox-edit-button,
.inbox-delete-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  color: white;
  transition: all 0.2s;
}

.inbox-edit-button {
  background: #6c757d;
}

.inbox-edit-button:hover:not(:disabled) {
  background: #5a6268;
}

.inbox-delete-button {
  background: #dc3545;
}

.inbox-delete-button:hover:not(:disabled) {
  background: #c82333;
}

.inbox-error-message {
  color: #dc3545;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.inbox-create-contact-form {
  background: white;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.inbox-form-group {
  margin-bottom: 15px;
}

.inbox-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s;
}

.inbox-form-group input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.1);
}
